import React from 'react'

function Privacy() {
  return (
    <div className='flex flex-col items-center w-3/4 mx-auto '>
        <h1 className='text-xl font-bold my-10'>Privacy policy</h1>
        <h2 className='sm:w-1/2 text-justify'>Con la presente acconsento ed autorizzo, irrevocabilmente e incondizionatamente, il trattamento dei miei dati personali inseriti (nome, cognome, data di nascita, e-mail, datore di lavoro, ruolo, facoltà, università...) al fine di valutare l'interesse esistente per un progetto nell'ambito 
            della formazione personale di studenti nonché della consulenza agli stessi ("il Progetto"). <br/> 
            Resta pertanto inteso che, il ricevente, Alberto Lelli, nato a Monza, il 12 Agosto 1995, CF LLLLRT95M12F704I, sarà autorizzato, nei limiti di quanto afferisce al Progetto a <br/>
            (i) condividere a terzi e trattare i miei dati personali nel pieno rispetto del D.Lgs. 2018/101 e del GDPR (Regolamento UE 2016/679); e <br/>
            (ii) contattarmi all'indirizzo e-mail fornito.
        </h2>
    </div>
  )
}

export default Privacy