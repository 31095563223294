import React from 'react'
import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features from '../components/features'
import background from '../images/debora-pilati-dOG0z4-gqp0-unsplash.jpg';

function Landing() {
  return (
    <>
    <div className="min-h-screen bg-cover bg-center bg-fixed" style={{backgroundImage: `url(${background})`}}>
        <Navbar/>
        <Hero/>
        <Features/>
    </div>
    
    </>
    
  )
}

export default Landing