import React from 'react'
import think from '../images/thinking.png'
import maybe from '../images/maybe.png'
import programmer from '../images/programmer.png'
import student from '../images/undraw_education_f8ru_tr.png'
import career from '../images/undraw_undraw_undraw_businessman_e7v0_qrld_(1)_hvmv_tr.png'
import { Link } from 'react-router-dom'
function Features() {
const waitlist = () =>{
    console.log("Hello bitchhh")
}

  return (
    <>
    <div className=' w-3/4 mx-auto'>
        <div className='flex justify-center sm:mt-18 mt-16'>
            <h2 className='text-slate-700 text-3xl text-center'>
                Finalmente tutte le <b>risposte</b> ai tuoi <b>dubbi</b>.
            </h2>
        </div>
        <div className='grid sm:grid-cols-3 sm:mt-6'>
            

            <div className='flex sm:justify-end justify-center'>
                <div className=' shadow-2xl h-max w-5/6 sm:w-3/4 flex flex-col items-center sm:mt-8 mt-16'>
                    <div className=''>
                        <img src={think} className='h-24'></img>
                    </div>
                    <h2 className='mt-4 p-3 text-slate-700 text-lg'>
                        <b>Chissà com'è lavorare in quel ruolo...</b>
                    </h2>
                </div>
                
            </div>
            <div className='flex sm:justify-center justify-center'>
                <div className=' shadow-2xl h-max w-5/6 sm:w-3/4 flex flex-col items-center sm:mt-8 mt-16'>
                    <div className=''>
                        <img src={maybe} className='h-24'></img>
                    </div>
                    <h2 className='mt-4 p-3 text-slate-700 text-lg '>
                        <b>Mi piacerà davvero quell'industria?</b>
                    </h2>
                </div>
                
            </div>
            <div className='flex sm:justify-start justify-center'>
                <div className=' shadow-2xl h-max w-5/6 sm:w-3/4 flex flex-col items-center sm:mt-8 mt-16'>
                    <div className=''>
                        <img src={programmer} className='h-24'></img>
                    </div>
                    <h2 className='mt-4 p-3 text-slate-700 text-lg '>
                        <b>Quali prospettive avrò con la mia laurea?</b>
                    </h2>
                </div>
                
            </div>

        </div>

        <div className='flex justify-center sm:mt-18 mt-20'>
                <h2 className='text-slate-700 text-3xl '>
                    Chiedilo a persone <b>specializzate</b> nei ruoli che ti interessano.
                </h2>
        </div>

        <div className='grid sm:grid-cols-3 sm:mt-16 mt-10 '>
            <div>
                <img src={student} className=''></img>
            </div>
            <div className='sm:col-span-2 sm:flex sm:flex-col sm:justify-evenly mt-4 sm:mt-0'>
                <h2 className='text-slate-700 text-3xl '>
                    Se sei uno <b>studente</b>
                </h2>
                <ul className='list-disc text-2xl list-inside text-slate-700 mt-8 sm:mt-4'>
                    <li className='mt-2'>
                        Iscriviti alla nostra piattaforma
                    </li>
                    <li className='mt-2'>
                        Seleziona la persona con cui vorresti parlare, è lì per aiutare te!
                    </li>
                    <li className='mt-2'>
                        Fissa un colloquio personalizzato e chiarisci tutti i tuoi dubbi!
                    </li>
                </ul>
            </div>
            
        </div>

        <div className='grid sm:grid-cols-3 sm:mt-16 mt-10 '>
            
            <div className='sm:col-span-2 sm:flex sm:flex-col sm:justify-evenly mt-4 sm:mt-0 order-2 sm:order-1'>
                
        
                    <h2 className='text-slate-700 text-3xl '>
                        Se già <b>lavori</b>
                    </h2>
                    <ul className='list-disc text-2xl list-inside text-slate-700 mt-8 sm:mt-0'>
                        <li className='mt-2'>
                            Metti a disposizione la tua esperienza 
                        </li>
                        <li className='mt-2'>
                            Richiedi un compenso orario per la tua consulenza
                        </li>
                        <li className='mt-2'>
                            Scopri talenti per la tua organizzazione
                        </li>
                    </ul>
            </div>
            
            <div className='order-1 sm:order-2'>
                <img src={career} className=''></img>
            </div>
            
        </div>

        <div className='flex flex-col items-center sm:mt-18 mt-20'>
            <h2 className='text-slate-700 text-3xl text-center'>
                <b>Noi siamo quasi pronti... e tu?</b>
            </h2>
            
            <button className=' w-5/6 mt-8 sm:w-1/2 h-12 rounded-xl text-sky-800 font-bold border-sky-800 border-2 hover:bg-sky-800 hover:text-white' >
                
                <Link to="/waitlistform">Registrati alla waitlist ed unisciti alla community</Link>
                
            </button>
            
            
            
        </div>


    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    
    </>
    
  )
}

export default Features