import React from 'react'

import visual from '../images/visual_hero.png'
import { Link } from 'react-router-dom'

function Hero() {
  return (
    <>
       <div className='w-3/4 mx-auto my-4  '>
            <div className='grid sm:grid-cols-3'>
                <div className='  flex flex-col sm:col-span-2'>
                    <h1 className='text-6xl text-slate-700'>
                        Connettiamo i giovani con i lavoratori
                    </h1>
                    <h2 className='text-3xl mt-2 text-slate-700'>
                        Tramite la nostra piattaforma studenti ansiosi di risposte parlano con professionisti disponibili ad aiutarli nel loro percorso di carriera.
                    </h2>
                    
                    <div className='mt-8'>
                        <Link to="/waitlistform" >
                            <button className=' w-1/2 h-12 rounded-xl text-sky-800 font-bold border-sky-800 border-2 hover:bg-sky-800 hover:text-white'>
                                Registrati alla waitlist 
                            </button>
                        </Link>
                    </div>
                    
                </div>
                <div className='sm:display sm:block display hidden'>
                    <img src={visual}>
                    </img>
                </div>

            </div>
       </div>
        
    </>
  )
}

export default Hero