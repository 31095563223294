
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import background from './images/debora-pilati-dOG0z4-gqp0-unsplash.jpg';
import Features from "./components/features";
import Landing from "./pages/Landing";
import NotFound from "./pages/NotFound";
import { Routes, Route } from "react-router-dom";
import WaitListForm from "./pages/WaitListForm";
import Privacy from "./pages/Privacy";


function App() {
  return (

    <>
    <div className="App">

    <div className="min-h-screen bg-cover bg-center bg-fixed" style={{backgroundImage: `url(${background})`}}>
      <Routes>
      
        <Route path="/" element={<Landing/>} />
        <Route path="/waitlistform" element={<WaitListForm/>} />
        <Route path="/privacy" element={<Privacy/>} />
        
        <Route path="*" element={<NotFound/>} />
      
      </Routes>
      
        
      
      </div>
    </div>
    </>
    

  );
}

export default App;
