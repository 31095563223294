import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function WaitListForm() {


    

    const [std, setStd] = useState("Studente");

    const [message, setMessage] =useState(false)

    const [accept, setAccept] = useState(false)

    const [user, setUser] = useState(
        {
            check:"",
            nome:"",
            cognome:"",
            email:"",
            birthdate:"",
            study:"",
            facoltà:"",
            azienda:"",
            ruolo:"",
            note:"",
            checkconsent:"",
            price:""  
            
        }
    )

    

    
    
    const handleChecks = () => {
        
        setUser({...user, ["check"] : std})
        setUser({...user, ["checkconsent"] : accept})
        console.log(user)
        
        
    }
    


    const handleChange = (ev) => {
        setUser({...user, [ev.target.name]: ev.target.value})
        console.log(user)
        
        
    }

    const handleForm = async (ev) =>{
        ev.preventDefault()
        
        console.log(user)
        
        const options = {
            method:"POST",
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(user)
        }

        const res = await fetch('https://enlight-smoke-test-repo-default-rtdb.europe-west1.firebasedatabase.app/UserData.json',
            options)
            if(res){
                setMessage(true)
            }else{
                console.log("Error")
            }

    }

  return (
    <>
    <div className=''>
        <div className='w-5/6 flex flex-col mx-auto '>
            
        <h1 className='mx-auto mt-4 text-slate-700 sm:text-6xl text-4xl'>Registrati alla waitlist </h1>
        <h2 className=' text-center mx-auto mt-4 text-slate-700 sm:text-3xl text-2xl '>Appena saremo pronti ti ricontatteremo per far parte della community!</h2>
        
        <div className=' mt-4 text-center w-5/6 mx-auto'>

            <h2 className='text-xl font-bold text-slate-700 m-4'>Sei un/una: </h2>
            <form onSubmit={handleForm}>
                <div className='flex justify-between sm:justify-center text-xl text-slate-700 m-4 '>
                    <div className='flex items-center p-2' >
                        <input type='radio' name="check" id='student' onClick={()=>{setStd("Studente")}}  className='m-2 w-6 h-6 ' defaultChecked/>
                        <label for='student'>Studente</label>
                    </div>
                    <div className='flex items-center p-2' >
                        <input type='radio' name="check" id='worker' onClick={()=>{setStd("Lavoratore")}} className='m-2 w-6 h-6'/>
                        <label for='worker'>Lavoratore </label>
                    </div>
        
                </div>
                <div>
                    <div className='flex sm:flex-row sm:justify-center flex-col  m-4'>
                        <input onChange={handleChange} type='text' name='nome' placeholder='Nome' className='p-2 m-4 border border-slate-500 rounded-md' required/>
                        <input onChange={handleChange} type='text' name='cognome' placeholder='Cognome' className='p-2 m-4 border border-slate-500 rounded-md' required/>
                    </div>
                    <div className='flex flex-col sm:items-center '>
                        <input onChange={handleChange} type='email' name='email' placeholder='E-mail' className='p-2  sm:w-1/2 border border-slate-500 rounded-md ' required/>
                        <label for='birthdate' className='text-xl text-slate-700 font-bold mt-4'>Data di nascita: </label>
                        <input onChange={handleChange} type='date' name='birthdate' className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md ' required/>
                    </div>
                </div>
                { std==="Studente" &&
                <div className='flex flex-col sm:items-center'>
                    <label for='study' className='text-xl text-slate-700 font-bold mt-4'>Studi in:</label>
                    <select onChange={handleChange} className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md ' name='study' id='study' required>
                        <option value=''>---</option>
                        <option value='liceo'>Università</option>
                        <option value='università'>Liceo</option>
                    </select>
                    <label for='facoltà' className='text-xl text-slate-700 font-bold mt-4'>Facoltà/Liceo:</label>
                    <input onChange={handleChange} type='text' name='facoltà' placeholder='ex. Economia / Liceo classico' className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md' required/>
                    <label for='price' className='text-xl text-slate-700 font-bold mt-4'>Quanto pagheresti per una consulenza di un'ora?</label>
                    <input onChange={handleChange} type='number' name='price' placeholder='€' className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md' required/>
                </div>
                }
                { std==="Lavoratore" &&
                    
                    <div className='flex flex-col sm:items-center'>
                        
                        <label for='azienda' className='text-xl text-slate-700 font-bold mt-4'>Azienda:</label>
                        <input onChange={handleChange} type='text' name='azienda' placeholder='Azienda' className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md' />
                        <label for='ruolo' className='text-xl text-slate-700 font-bold mt-4'>Ruolo:</label>
                        <input onChange={handleChange} type='text' name='ruolo' placeholder='Ruolo' className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md' required/>
                        <label for='price' className='text-xl text-slate-700 font-bold mt-4'>Quanto faresti pagare una consulenza di un'ora?</label>
                        <input onChange={handleChange} type='number' name='price' placeholder='€' className='p-2 m-2 sm:w-1/2 border border-slate-500 rounded-md' required/>    
                        
                    </div>
                
                }

                <div className='flex flex-col sm:items-center'>
                    <label for='note' className='text-xl text-slate-700 font-bold mt-4'>Lasciaci un commento!</label>
                    <input onChange={handleChange} type='text' name='note' placeholder='Cosa ti aspetti da Enlight?' className='p-2 m-2 sm:w-1/2 h-20 overflow-auto border border-slate-500 rounded-md'/>
                </div>
                <div className='flex sm:justify-center items-center m-4'>
                    
                    <input onChange={handleChecks} type='checkbox' name='checkconsent'  className='p-2 m-2 sm:w-6 sm:h-6 w-10 h-10' onClick={()=>{setAccept(!accept)}}/>
                    <label for='nocheckconsentte'>Accetto il trattamento dei dati secondo l'<Link target='_blank' to="/privacy"  className='text-blue-500 underline font-bold'>Informativa privacy</Link></label>
                </div>


                {  accept 
                
                ? 
                
                <div className='flex flex-col sm:items-center'>
                    <button type='submit' className=' sm:w-1/2 h-12 rounded-xl bg-sky-500 text-white text-xl'>
                        Registrati alla waitlist 
                    </button>
                </div>

                :

                <div className='flex flex-col sm:items-center'>
                    <button disabled className=' sm:w-1/2 h-12 rounded-xl bg-sky-500 bg-opacity-50 text-white text-xl'>
                        Registrati alla waitlist 
                    </button>
                </div>

                }

                


                

            </form>

            {message &&
            <h2 className='font-bold text-green-600 text-lg mt-4'>
                Messaggio inviato, grazie mille per il tuo interesse! <br/>
                
            </h2> }

        </div>
        <br/>
        <br/>
        </div>
        </div>

        

    </>
  )
}

export default WaitListForm