import React from 'react'

import logo from '../images/enlight_logo.png'

function Navbar() {
  return (
    <>
        <div  >
            <img src={logo} className='h-24 p-2 sm:p-2 md:h-36'></img>
        </div>
        
    
    </>
  )
}

export default Navbar